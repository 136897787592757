/**
 * @author [Peter]
 * @email [hoangvanlam9988@mail.com]
 * @create date 2022-02-19 15:25:35
 * @modify date 2022-02-19 15:25:35
 * @desc [description]
 */

import React, { useEffect, useState } from 'react'
import { UrlInternal } from 'common/constants/endpoints'
import { useSelector } from 'react-redux'
import { Redirect, Route, useHistory } from 'react-router-dom'
import { getLocalStorage } from 'utils/localStorage'

export function PublicRoute({
  component: TargetPage,
  ...rest
}: any): React.ReactElement {
  return <Route {...rest} render={(props: any) => <TargetPage {...props} />} />
}

export function PrivateRoute({
  component: TargetPage,
  isAuthenticated = true,
  ...rest
}: any): React.ReactElement {
  const history = useHistory()
  const userData = useSelector((state: any) => state.user)
  const isLogin = localStorage.getItem('User')
  return (
    <Route
      {...rest}
      render={(props: any) =>
        !isLogin || !isAuthenticated ? (
          <Redirect
            to={{
              pathname: UrlInternal.LOGIN,
              state: { fromURL: props?.location?.pathname }
            }}
          />
        ) : (
          <TargetPage {...props} />
        )
      }
    />
  )
}
